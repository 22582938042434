.puzzle-selector-modal .modal-lg {
  max-width: 1200px !important;
}

.puzzle-selector-modal  .modal-content {
  border: 2px solid #bbb;
  border-radius: 0.5rem;
  box-shadow: 0 0 1rem 1rem rgba(0, 0, 0, 0.2);
}

.puzzle-selector {
  max-height: calc(35em);
  min-height: calc(35em);
  overflow-y: auto;
  overflow-x: hidden;
}
table.puzzle-selector {
  margin-bottom: 0px !important;
  border: none !important;
}
.puzzle-selector tbody td {
  cursor: default;
  border-bottom: 1px solid #dee2e6;
}
[data-bs-theme="dark"] .puzzle-selector tbody td {
  border-bottom: 1px solid #444;
}

.puzzle-selector thead th {
  padding: 1em 0 1em 1em;
  border-bottom: 0px;
}
.puzzle-selector .nav-link {
  padding: 0;
}

/* Columns  */

.puzzle-selector .icon {
  width: 3em;
  height: 2.5em;
  background: linear-gradient(0deg, #e8e8e8, #fff);
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 0px;
  border-right: 0px;
  cursor: pointer;
  padding: 0;
  display: block;
  /* content: " "; */
}

[data-bs-theme="dark"] .puzzle-selector .icon {
  background: linear-gradient(0deg, #111, #222);
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
  border-left: 0px;
  border-right: 0px;
}
.puzzle-selector .icon img {
  width: 3em;
  height: 3em;
  padding: 0.15em;
  margin-left: -0.1em;
  margin-top: -0.3em;
}
.puzzle-selector .name {
  font-size: 1.25em;
  line-height: 0.75em;
  padding-left: 0.5em;
  cursor: pointer;
  font-weight: 600;
  font-style: italic;
}

.puzzle-selector .region {
  font-size: 1em;
  line-height: 2.5em;
  padding: 0 0 0 1em;
  cursor: pointer;
}
.puzzle-selector .subregion {
  font-size: 1em;
  line-height: 2.5em;
  padding: 0 0 0 1em;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.puzzle-selector .font-weight-bold {
  font-weight: bold !important;
}

/* Header */

.puzzle-selector .icon-header {
  width: 3em;
}
.puzzle-selector .name-header {
  width: 47%;
  padding: 1em 0 0.75em 1em;
}
.puzzle-selector .region-header {
  width: 20%;
}
.puzzle-selector .subregion-header {
  width: 30%;
}

/* Search */
.puzzle-selector .form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.5em);
  padding: 0.25rem 0.5rem;
  font-size: 1rem;

  line-height: 1.5;
  background-clip: padding-box;
  border: 1px solid #e5e5e5;
  border-radius: 0;
}
.puzzle-selector .form-control::-webkit-input-placeholder {
  font-weight: bold;
}
.puzzle-selector .form-control:focus {
  border-color: #a1a1a1;
  box-shadow: none;
}
[data-bs-theme="dark"] .puzzle-selector .form-control {
  border-color: #333;
}

.puzzle-selector .dropdown-menu {
  max-height: calc(50vh);
  max-width: calc(95vw);
  overflow-y: auto;
}

.puzzle-selector .table {
  margin-bottom: 0.4em;
}
.puzzle-selector .no-found {
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  padding: 1em;
}
.puzzle-selector .no-found h3 {
  font-size: 1.5em;
  font-weight: bold;
  padding: 0em;
}

/* disable on mobile  region and subregion */
@media (max-width: 768px) {

  .puzzle-selector .region,
  .puzzle-selector .subregion,
  .puzzle-selector .subregion-header,
  .puzzle-selector .region-header {
    display: none;
  }
  .puzzle-selector .icon-header {
    width: 3em;
  }
  .puzzle-selector .name-header {
    width: 97%;
  }
  .react-bootstrap-table-pagination-total {
    display: none;
  }
}
.puzzle-selector-modal .react-bootstrap-table-pagination-total {
  color: #a1a1a1;
  font-style: italic;
  margin-left: 3em;
}

.puzzle-selector .react-bootstrap-table {
  min-height: calc(30em);
} 
.puzzle-selector .pagination {
  display: flex;
  list-style: none;
  padding-top: 0.75em;
  float: right;
  user-select: none;
}

.puzzle-selector-modal .clean-icon {
  padding: 0;
  margin: 0;
}

.puzzle-selector-modal .clean-icon:focus,
.puzzle-selector-modal .clean-icon:active,
.puzzle-selector-modal .clean-icon:hover,
.puzzle-selector-modal .clean-icon:visited,
.puzzle-selector-modal .clean-icon:link,
.puzzle-selector-modal .clean-icon:enabled {
  border: none !important;
  box-shadow: none !important;
}

.puzzle-selector .selected td{
  background-color: #ff0000 !important

}