body{
  overflow: hidden;
}
.navbar-brand > img {
  max-height: 32px;
  padding-right: 4px;
  height: 32px;
  width: 38px;
}

.piece-quiz > .timer {
  text-align: right;
  color: black;
  font-style: italic;
  position: absolute;
  z-index: 1;
  margin-left: calc(30vw - 210px);
  width: 200px;
  margin-top: 10px;
}
.piece-quiz > .timer b {
  color: darkred;
  font-style: normal;
}

.quiz-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);
  background: #cbe1e4;
}

[data-bs-theme="dark"] .quiz-container {
  background: #2c353c;
}

.flag-container {
  width: 30vw ;
  height: calc(35vh - 56px);
  background: gray;
  background-size: auto 100% ;
  background-repeat: repeat-x;
  background-position: center;
  flex: 1;
  max-height: calc(42vh - 56px - 37px);
}

.questions {
  margin-top: 2vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
  padding:0em 1em 1em 1em;
  max-width: 100%;
  height: calc(65vh - 56px);
}
@media screen and (max-width: 992px) {


  .flag-container 
  {
    background-size: auto 250%;    
  } 
}
@media screen and (max-width: 576px) {


  .flag-container 
  {
    background-size: auto 200%;    
  } 
}



.deckgl-container {
  flex: 30vw;
  height: 100vh;
}

.deckgl-container.winner > div {
  height: 100vh !important;
  width: 100vw !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
}

.deckgl-container > div {
  height: 100vh !important;
  width: 70vw !important;
  margin-left: 30vw !important;
}


.piece-quiz {
  position: absolute;
  max-width: min-content;
}

