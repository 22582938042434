
.timer {
    float: right;
    text-align: right;
    color: darkgray;
    font-style: italic;
  }
  .timer b {
    color: darkred;
    font-style: normal;
  }
  .timer ul {
    list-style-type: none;
    margin: 0;
  }
  
  .timer ul#hours {
    margin-top: -6px !important;
    margin-bottom: -6px !important;
    font-size: 11px;
    line-height: 11px;
  }
  .timer ul#minutes {
    margin-top: -2px !important;
    margin-bottom: -2px !important;
    font-size: 13px;
    line-height: 13px;
  }
  .timer ul#seconds {
    margin-top: 4px !important;
    margin-bottom: 0px !important;
    font-size: 14px;
    line-height: 14px;
  }
  