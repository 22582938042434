.flagGradient img {
    width: 75px;
    height: 75px;
    padding: 0;
    display: block;
    position: absolute;
    opacity: 0.25;
    z-index: -2;
    top: -16px;
  }
  .flagGradient {
    position: absolute;
    margin-top: -12px;
    margin-left: -13.5px;
  }
  .flagGradient::after {
    content: " ";
    position: absolute;
    width: 76px;
    height: 47px;
    background: linear-gradient(
  to right,
  rgba(255, 255, 255, 0) 25%,
  rgba(255, 255, 255, 1) 90%
  );
    z-index: -1;
    top: -2px;
  }
  [data-bs-theme="dark"] .flagGradient::after {
    background: linear-gradient(
      to right,
      rgba(39, 43, 48, 0) 25%,
      rgba(39, 43, 48, 1) 100%
      );
  }

  .toolsPanelContainer .accordion-button {
    min-height: 3em;
    padding: 0.7em;
    background: white;
    z-index: 1;
    display:inline-block;
    border-radius: 0;
    margin-bottom: -2px;

  }
  .toolsPanelContainer .accordion-button::after {
    z-index: 1;
    position: absolute;
    top: 29px;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: 0.5;
  }
  .toolsPanelContainer .mapTimer {
    min-height: 20px;
  }

  .toolsPanelContainer .accordion-button {
    background: white;
  }

  [data-bs-theme="dark"] .toolsPanelContainer .accordion-button {
    background: #272b30;
  }
  
  .toolsPanelContainer .accordion-item:first-of-type {
    border-radius: 0;
  }
  .toolsPanelContainer .mapName {
    font-weight: 600;
    margin: -0.75rem -0.25rem;
    height: 3em;
    position: absolute;
    width: calc(100% - 5.5rem);
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    line-height: 0.95em;
  }
  .toolsPanelContainer .timer {
    min-height: 20px;
    max-height: 20px;
    overflow: visible;
  }