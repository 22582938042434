.close-icon {
  background-image: url("data:image/svg+xml,%3Csvg xml:space='preserve' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M363.747 148.753c-59.228-59.227-155.267-59.227-214.494 0-59.227 59.228-59.227 155.267 0 214.494 59.228 59.227 155.267 59.227 214.494 0 59.227-59.227 59.227-155.267 0-214.494zM256.854 281.81l-71.418 71.418-26.163-26.163 71.417-71.418-70.71-70.711 25.456-25.456 70.711 70.71 70.711-70.71 26.163 26.163-70.711 70.71 70.711 70.711-25.456 25.456-70.711-70.71z' fill='%23FFFFFF'/%3E%3C/svg%3E") !important;
  display: inline-block;
  width: 2em;
  height: 2em;
  margin-top: -0.3em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-image: none;
  background-size: auto;
  background-size: 100% 100%;
}


  