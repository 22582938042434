
.scorewin.row {
  margin: 0px -16px;
}

.youWin .modal-body {
  padding: 0 1rem;
}
.youWin .modal-body p {
  margin-bottom: 0px;
}
.youWin .scorewin .alert {
  border-radius: 0px;
  margin-bottom: 0px;
  padding: 0.75rem 0rem;
}
.youWin .scorewin .alert-warning > .mb-0 {
  font-size: 20px !important;
  line-height: 40px;
}
.youWin .scorewin .alert-heading.h4,
.youWin .scorewin .mb-0 {
  font-size: 25px !important;
  font-weight: bolder;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  line-height: 25px;
  margin: 1.5em 0em !important;
}

.youWin .scorewin hr {
  margin: 0px;
}

.youWin .scorewin .col-lg-4 {
  padding-right: 0px;
  padding-left: 0px;
}

.youWin .share {
  text-align: center;
  padding: 1.5em;
}
.youWin .share h4 {
  padding-bottom: 0.5em;
}