.alertWiki .modal-body {
  padding: 0 1rem;
}
.alertWiki .modal-body p {
  margin-bottom: 0px;
}
.alertWiki .imgFlag, .alertWiki .imgWiki {
  float: right;
  width: 35%;
  margin: 15px 0px 10px 23px;
  position: relative; /* Añade esta línea */
}

.alertWiki .fakeFlag {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    125deg,
    #0006 0%,
    #fff4 25%,
    #0006 50%,
    #fff4 75%,
    #0006 100%
  );
  z-index: 1;
  pointer-events: none;
  /*blur effect*/
  filter: blur(20px);
  -webkit-filter: blur(20px);
  /* hide outside the parent */
  clip: rect(0, auto, auto, 0);
}

.alertWiki .imgFlag img {
  width: 100%;
  height: auto;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.2);
}
.alertWiki .infoWiki {
  text-align: justify;
  padding: 1.5em;
  color: #555;
  max-height: 500px;
  overflow-y: auto;
}

[data-bs-theme="dark"] .alertWiki .infoWiki {
  color: #aaa;
}

.alertWiki .infoWiki p {
  padding-bottom: 0.5em;
}
.alertWiki .infoWiki h2 {
  padding-bottom: 0.25em;
  font-weight: bold;
  font-size: 1.5em;
}

[data-bs-theme="dark"] .alertWiki .modal-header {
  background-color: #343a40;
}

[data-bs-theme="dark"] .alertWiki .infoWiki {
  color: #aaa;
}
