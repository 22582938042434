.editor-dialog .modal-header {
  background-color: goldenrod;
}
.editor-dialog .modal-body {
  padding: 0.5rem 1rem;
  overflow-y: auto;
  min-height: calc(100vh - 250px);
}
.editor-dialog .modal-body p {
  margin-bottom: 0px;
}
.editor-dialog-backdrop.modal-backdrop.show {
  opacity: 0.8;
}
.piece-preview {
  border: 1px solid #ccc;
  display: inline-flex;
  padding: 10px;
  transform: scale(1.5); 
  margin-top: 20%;
  margin-left: 50%;
  box-shadow: 3px 3px 3px 0px rgba(0, 0, 0, 0.15);
}
.btn-cursor {
  width: 50px;
  height: 50px;
}

.piece-poi {
  position: absolute;
  width: 5px;
  height: 5px;
  background-color: #000;
  border: 1px solid lightgray;
  border-radius: 5px;
  overflow: hidden;
}

.full-height {
  height: calc(30vh);
}

.flag-selector > .dropdown-menu {
  max-height: calc(40vh);
  max-width: calc(95vw);
  overflow-y: auto;
}
.flag-selector > .nav-link {
  padding: 0;
  margin: -3px;
}
.flag-selector.dropdown-item {
  padding: 0px 0px 4px 3px;
  border-bottom: 1px solid lightgray;
}

.flag-selector-icon {
  display: inline-block;
  width: 3em;
  height: 2.5em;
  vertical-align: middle;
  margin: -0.3em 0.5em -0.3em -0em;
}

.wikiIframe {
  height: calc(100vh - 27em);
  width: 100%;
}

iframe {
  overflow-x: hidden;
}

.scrollable {
  overflow-y: auto;
  max-height: calc(100vh - 19em);
}

.editor-dialog .modal-xl {
  --bs-modal-width: 1365px;
}
.save-btn {
  margin-left: auto;
  height: 2em;
  line-height: 0em;
}

.right-align {
  display: contents;
}
