.btn-quiz {
  cursor: pointer;
  border-width: 1px;
  border-radius: 5px;
  font-size: 2vh;
  font-weight: 500;
  -webkit-box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow:0px 4px 4px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 0;
  -moz-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  -webkit-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  transition: all 0.6s ease;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.btn-quiz.rtl {
  text-align: right;
}


.btn-quiz span {
  z-index: 1;
}
.btn-quiz:hover,
.btn-quiz:active,
.btn-quiz:focus {
  outline: none !important;
}
.btn-quiz:hover:after {
  top: 0;
}
.btn-quiz.btn-primary {
  border: 0px;
}
.btn-quiz.btn-primary:hover,
.btn-quiz.btn-primary:focus {
  background: #0525a7 !important;
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.6);
  -moz-box-shadow:0px 4px 8px 0px rgba(0, 0, 0, 0.6);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.6);

}
.btn-quiz.btn-primary.btn-link {
  background: transparent;
  color: #fff;
  border: none;
}
